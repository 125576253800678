import { ChangeEvent, Fragment, useEffect, useReducer, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import { getCurrentWalletPublicKey, getUserSessionData, getBalance, getPublisherNew, getConnectionConfig, createGachaCollection, getPrivateKey, getPrivateKeyBase58, continueAssetUpload, getPublicWallet, doZipUpload, saveDraftGacha, backend_api_url, base64ToFile, chunkArray, getClientOnRampSecret, cluster, zipFileToFile, getExplorerUrl} from "./constants";
import { toast } from "react-toastify";
import { useConnection } from '@solana/wallet-adapter-react';
import { Keypair, PublicKey, LAMPORTS_PER_SOL, Transaction, SystemProgram} from "@solana/web3.js";
import base58 from "bs58";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import fs from 'fs'
import { SolanaWallet } from "@web3auth/solana-provider";
import { Cropper, CropperRef } from 'react-advanced-cropper'
import 'react-advanced-cropper/dist/style.css';
import JSZip from "jszip";
import { Navigation } from "./Navigation";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";

import { getAbsoluteZoom, getZoomFactor } from 'advanced-cropper/extensions/absolute-zoom';
import OnRamp from "./OnRamp";
import PaginatedDesigns from "../PaginatedDesigns";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import axios from "axios";

interface UploadInfo {
  type: string;
	name: string;
	file: string;
  actualFile: File;
  isFound: Boolean
}
 
const CreateCollection = (props: any) => {

    const [myMap, setMyMap] = useState(new Map());

    const [hasImageChanges, setHasImageChanges] = useState(true);

    const [showSubmitConfirm, setShowSubmitConfirm] = useState(false);
    const handleCloseSubmitConfirm = () => setShowSubmitConfirm(false);
    const handleShowSubmitConfirm = () => setShowSubmitConfirm(true);

    const [showSubmitConfirm1, setShowSubmitConfirm1] = useState(false);
    const handleCloseSubmitConfirm1 = () => setShowSubmitConfirm1(false);
    const handleShowSubmitConfirm1 = () => setShowSubmitConfirm1(true);



    const [showClear, setShowClear] = useState(false);
    const handleCloseClear = () => setShowClear(false);
    const handleShowClear = () => setShowClear(true);

    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);
    

    const [isReadOnly, setIsReadOnly] = useState(false);

    const [isAvailable, setIsAvailable] = useState(0);

    const [showPreview, setShowPreview] = useState(false);
    const handleClosePreview = () => setShowPreview(false);
    const handleShowPreview = () => setShowPreview(true);
    const [previewImageName, setPreviewImageName] = useState<JSX.Element>(null);

    const [previewImageDesc, setPreviewImageDesc] = useState("");
    const [previewCollectionName, setPreviewCollectionName] = useState<JSX.Element>(null);
    const [propertiesJsx, setPropertiesJsx] = useState<JSX.Element[] | null>(null)
    const [previewImageUrl, setPreviewImageUrl] = useState("");

    const [id, setId] = useState("");


    const [jsonUploaded, setJsonUploaded] = useState([]);

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileLimit, setFileLimit] = useState(false);

    const nameInput = useRef<HTMLInputElement>(null);
    const symbolInput = useRef<HTMLInputElement>(null);

    const editionInput = useRef<HTMLSelectElement>(null);
    const numOfNftInput = useRef<HTMLInputElement>(null);
    const numOfOpenNftInput = useRef<HTMLInputElement>(null);

    const tokenAcceptedInput = useRef<HTMLSelectElement>(null);
    const priceInput = useRef<HTMLInputElement>(null);
    const enforceRoyaltiesInput = useRef<HTMLSelectElement>(null);
    const royaltiesInput = useRef<HTMLInputElement>(null);
    const descriptionInput = useRef<HTMLTextAreaElement>(null);

    const hiddenFileInput = useRef<any>(null);
    const hiddenFileBannerInput = useRef<any>(null);

    const hiddenFileUploaderInput = useRef<any>(null);
    const hiddenFileUploaderInputZip = useRef<any>(null);

    const [currentUserId, setCurrentUserId] =  useState(0);
 
    const [currentCreator, setCurrentCreator] = useState("");
    const [creators, setCreators] =  useState<any[]>([]);
    const [creatorJsx, setCreatorJsx] = useState<JSX.Element[] | null>(null)
    const [statusJsx, setStatusJsx] = useState<JSX.Element[] | null>(null)

    const statusInput = useRef<HTMLSelectElement>(null);
    const [statusValue, setStatusValue] =  useState<string>("0");

    const [avatarImage, setAvatarImage] =  useState<string>("/collection.png");
    const [bannerPhoto, setBannerPhoto] =  useState<string>("");
    const [hasBannerPhoto, setHasBannerPhoto] =  useState<boolean>(false);

    const [estimateSol, setEstimateSol] =  useState('0');

    const [allFiles, setAllFiles] =  useState<File[]>([]);
    const [tags, setTags] = useState([]);
    const [minSol, setMinSol] = useState<number>();
    const [solBalance, setSolBalance] = useState<number>(0);
 
    const [numOfDesigns, setNumOfDesigns]  = useState<number>(0);

    const [uploadedItems, setUploadedItems]  = useState<number>(0);
    const [toBeUploaded, setToBeUploaded]  = useState<number>(0);
    const [stepName, setStepName] = useState<string>('');

    const [editionType, setEditionType] = useState<string>('Open');
    const [enforceRoyalties, setEnforceRoyalties] = useState<string>('Yes');

    
    const [allFilesNew, setAllFilesNew] = useState<UploadInfo[]>([])
    const [allFilesNewJson, setAllFilesNewJson] = useState<UploadInfo[]>([])

    const [allFilesMerged, setAllFilesMerged] = useState<UploadInfo[]>([])
    const [isProcessing, setIsProcessing] = useState(false);

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isView, setIsView] = useState<boolean>(false);

    const { connection } = useConnection();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [showSaving, setShowSaving] = useState(false);
    const handleCloseSaving = () => setShowSaving(false);
    const handleShowSaving = () => setShowSaving(true);
       
    const [showPreviewAvatar, setShowPreviewAvatar] = useState(false);
    const handleClosePreviewAvatar = () => setShowPreviewAvatar(false);
    const handleShowPreviewAvatar = () => setShowPreviewAvatar(true);

    const [avatarPreview, setAvatarPreview] = useState<string>("");

    const [showPreviewBanner, setShowPreviewBanner] = useState(false);
    const handleClosePreviewBanner = () => setShowPreviewBanner(false);
    const handleShowPreviewBanner = () => setShowPreviewBanner(true);

    const [bannerPreview, setBannerPreview] = useState<string>("");

    const [userId, setUserId] = useState(0);
    const [candyMachineId, setCandyMachineId] = useState(0);
    

    const [isCreateSuccessInfo, setIsCreateSuccessInfo] = useState(false); 
    const [successMessage, setSuccessMessage] = useState(""); 

    const [gachaAddress, setGachaAddress] = useState<string>("");

    const [hasJsonData, setHasJsonData] = useState(false);


    
    const [secret, setSecret] = useState<string>('');
    const [showStripe, setShowStripe] = useState(false);
    const handleCloseStripe = () => setShowStripe(false);
    const handleShowStripe = () => setShowStripe(true);
 
    const [showErrorPurchase, setShowErrorPurchase] = useState(false);
    const handleCloseErrorPurchase = () => setShowErrorPurchase(false);
    const handleShowErrorPurchase = () => setShowErrorPurchase(true);

    const [mintError, setMintError] = useState("");
    const [mintErrorHeader, setMintErrorHeader] = useState("");
    const [showBuyCrypto, setShowBuyCrypto] = useState(false);

    const [showRoyalty, setShowRoyalty] = useState(false);
    const [userWallet, setUserWallet] = useState("");

    const [isAdmin, setIsAdmin] = useState(false);
    const [isSameUser, setIsSameUser] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
 
     
    const [options, setOptions] = useState<any[]>([]);
 

    const [singleSelections, setSingleSelections] = useState([]);
  


    const openOnRamp = async () => {

        handleCloseError();
        setShowBuyCrypto(false);

        const clientSecret = await getClientOnRampSecret('SOL');
        setSecret(clientSecret);
        //console.log(clientSecret);
        handleShowStripe();

        //console.log(getUserSessionData());

    }
    const showSuccessPayment = () => {
      handleCloseStripe();
      setMintErrorHeader('Notification');
      setMintError('Payment Successful');
      handleShowErrorPurchase(); 

    }

    const getEstimateSol = () => {
        /*

Fixed Cost per machine
- TxFee: 0.00034 SOL
- CreateMint = 0.022 SOL
- Transfer = 0.7875 SOL
- Create BBG merkle = 0.39544232 SOL

Variable Cost per CNFT:
- TxFee: 0.00034 SOL (MINT + VERIFY)

Formula in SOL = 1.205 + 0.00034x (where x is per cnft)

Should we increase base by 0.75 SOL when over 4k, and 1.5 SOL when over 8k? 10k max collection

Harry - input needed
Yeah something simple and linear like this 

0.75 * ( n / 4000 + 1)


        */
      //  const fixedCost = 1.205;
      //  const cnftFee = 0.00034;
       

      //const initialSol1 = 0.75;
      
      //setEstimateSol(initialSol1);
     // return initialSol1;

      //console.log('getEstimateSol');
          //For Solana: seems that 6.3 Sols for 9k collection, so 0.7 Sols for 1k collection, or 1 Sol for 1.4k collection.
      //I'll leave the data here for you to calculate:
      // - The highest upload fee for Bundlr for upload per file = 0.000406613 SOL (unknown file size)
      // - Account size for candy machine for 9k designs = 6.27872736 SOL
      // - fees: 0.00001 SOL 
       
      let  totalAvailable = parseInt(numOfOpenNftInput.current!.value);
         
      //ermove this one
      // if(totalAvailable < allFilesNew.length) {
      //   totalAvailable = allFilesNew.length
      // }
      
      //const initialSol =  1 * (  Math.floor(totalAvailable / 4000) + 1);
      const initialSol =  0.75 + (0.0005 * (totalAvailable));

      setEstimateSol('0'); 
      if(initialSol > 0) {
        setEstimateSol(initialSol.toFixed(5)); 
      }
      return initialSol;
    }

    const setIsCreateSuccess = (gachaAddress, message) => {
      setGachaAddress(gachaAddress);
      setIsCreateSuccessInfo(true);
      setSuccessMessage(message);
    }
    let params = useParams();

    const cropperRef = useRef<CropperRef>(null);
    const cropperRefBanner = useRef<CropperRef>(null);

    const onChangeCropper = (cropper: CropperRef) => {        
        //console.log(cropper.getCoordinates(), cropper.getCanvas());    
        // console.log(cropper.getCanvas()?.toDataURL());
        //setPreviewUrl(cropper.getCanvas()?.toDataURL());
    };
    const onChangeCropperBanner = (cropper: CropperRef) => {        
      //console.log(cropper.getCoordinates(), cropper.getCanvas());    
      // console.log(cropper.getCanvas()?.toDataURL());
      //setPreviewUrl(cropper.getCanvas()?.toDataURL());
    };
     
    const absoluteZoom = getAbsoluteZoom(cropperRef.current?.getState(), cropperRef.current?.getSettings());
  


    const onZoom = (value: number, transitions?: boolean) => {        
       

      cropperRef.current.zoomImage(getZoomFactor(cropperRef.current?.getState(), cropperRef.current?.getSettings(), value), 
        {            transitions: !!transitions,        });    
    };
 
    const doAbsoluteZoom = () => {
      return getAbsoluteZoom(cropperRefBanner.current?.getState(), cropperRefBanner.current?.getSettings())
    }

    const absoluteZoomBanner = getAbsoluteZoom(cropperRefBanner.current?.getState(), cropperRefBanner.current?.getSettings());
  

    

    const onZoomBanner = (value: number, transitions?: boolean) => {        
       
      console.log(value + "  " + getZoomFactor(cropperRefBanner.current?.getState(), cropperRefBanner.current?.getSettings(), value));
      console.log(cropperRefBanner.current?.getSettings());

       
      console.log(cropperRefBanner.current?.getSettings().sizeRestrictions);

      cropperRefBanner.current.zoomImage( getZoomFactor(cropperRefBanner.current?.getState(), cropperRefBanner.current?.getSettings(), value), 
        {            transitions: !!transitions,        });    
 
    };

    const saveCollectionAvatar = () => {
      const canvas = cropperRef.current?.getCanvas();
      const imageString = canvas?.toDataURL();

      setAvatarImage(imageString); 
      handleClosePreviewAvatar();
      if(isView || isEdit) {
        saveAvatarOnly(imageString);
      }
   }
   const saveCollectionBanner = () => {
    const canvas = cropperRefBanner.current?.getCanvas(
      {        height: 400,        width: 1280,}
    );
    const imageString = canvas?.toDataURL();

    setBannerPhoto(imageString); 
    setHasBannerPhoto(true);

    handleClosePreviewBanner();

    if(isView || isEdit) {
      saveBannerOnly(imageString);
    }
 }

 const showNftData = async (e, name) => {

    
    var jsonData = null;
    for(var i in allFilesNewJson) {
      
      if(allFilesNewJson[i].name === name.split('.')[0] + '.json') {
        jsonData = allFilesNewJson[i];
      }
    }

    var imageData = null;
    for(var i in allFilesNew) {
      
      if(allFilesNew[i].name.split('.')[0] + '.json' === name.split('.')[0] + '.json') {
        imageData = allFilesNew[i];
      }
    }
    
    setShowRoyalty(false);
    if( royaltiesInput.current!.value != ''  && parseFloat(royaltiesInput.current!.value) > 0) {
      setShowRoyalty(true);

      const userData = getUserSessionData();
      if(userData) {
        setUserWallet(userData.wallet);

        
        if (getSelectedCreator()  > 0) {
          let user_wallet = await getCustomUserWallet(getSelectedCreator());
          setUserWallet(user_wallet);

        }
        
      }else{
        setUserWallet("User Wallet");
      }
    }
    handleShowPreview(); 
 
    // console.log(name);
    // console.log(jsonData);
    setHasJsonData(false);
    if(jsonData) {

      


      setHasJsonData(true);
 
      
      setPreviewCollectionName(<Fragment>{nameInput.current!.value}</Fragment>);
      setPreviewImageDesc(descriptionInput.current!.value);

      setPreviewImageName(name.split('.')[0]);

      setPreviewImageUrl("");
      if(imageData) {
        setPreviewImageUrl(imageData.file);
        
      }

      if(jsonData.file.attributes) {
          const attributes = (jsonData.file.attributes);
          const properties = chunkArray(attributes, 2);
          const chunkedJSX = [];

          for (let i = 0; i < properties.length; i ++) {
              let elem = properties[i];

              if(elem.length === 2) {
                  chunkedJSX.push(
                      <div className='row-elem' key={i}>
                          {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                          {elem[1] &&  <div className="custom-column">{elem[1].trait_type}: <span className="prop-value">{elem[1].value}</span></div>} 
                      </div>
                  );
              }else{
                  chunkedJSX.push(
                      <div className='row-elem' key={i}>
                          {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                      </div>
                  );
              }
          }
          setPropertiesJsx (chunkedJSX);
      } 
    }
 }
  const saveAvatarOnly = (imageString) => {
    // console.log('save avatar only' + candyMachineId);

    const userData = getUserSessionData();


      const params = {
          token: userData.token,
          secret: userData.secret, 
          candyMachineId : candyMachineId,
          
          avatar: imageString,
      }
    
      const requestOptions = {
          method: 'POST',
          body: JSON.stringify(params)
      };
    
      let apiUrl = backend_api_url + 'api/v1/machine/save-avatar'
       
      fetch(apiUrl, requestOptions)
          .then(response => response.json())
          .then(data => { 
          });
  }
  const saveBannerOnly = (imageString) => {
      // console.log('save banner only');

      const userData = getUserSessionData();


      const params = {
          token: userData.token,
          secret: userData.secret,
          candyMachineId : candyMachineId,
          
          banner: imageString,
      }
    
      const requestOptions = {
          method: 'POST',
          body: JSON.stringify(params)
      };
    
      let apiUrl = backend_api_url + 'api/v1/machine/save-banner'
       
      fetch(apiUrl, requestOptions)
          .then(response => response.json())
          .then(data => { 
          });
    
  }

    const isWalletReady = () : boolean => {

        const walletPubKey = getCurrentWalletPublicKey();

		return (
            walletPubKey != null
		);
	};

    const loadWalletBalance = async () => {

        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) { 

                const pubkey =  new PublicKey(userData.wallet);

                const balance = await getBalance(pubkey, connection);
                setSolBalance(balance);
                return (balance);
            }
        }
        return 0;
    }

    const changeEdition = (event: any) => {
        event.preventDefault();
        setEditionType(editionInput.current!.value);
    }
    const changeEnforceRoyalties = (event: any) => {
      event.preventDefault();
      setEnforceRoyalties(enforceRoyaltiesInput.current!.value);
    } 
    const showPrivateKey = async (event: any) => {
        event.preventDefault();

        if(getUserSessionData() == null || props.provider == null) {
            props.loginWeb3Auth();
            return;
        }

        const web3authKey1 = await getPrivateKeyBase58(props.provider); 
        console.log(" Private Key: "+web3authKey1);


        const privateKey = await props.provider.request({
          method: "solanaPrivateKey",
        });
    
        let secretKeyUnit8Format = new Uint8Array(Buffer.from(privateKey, 'hex'));
        const publisher = Keypair.fromSecretKey(
          secretKeyUnit8Format
          );
          
        
        const privkey = new Uint8Array(publisher.secretKey); // content of id.json here

        console.log(base58.encode(privkey));
        
        const web3authKey = await getPrivateKeyBase58(props.provider);

        console.log(base58.encode(privkey) + " Private Key: "+web3authKey);
        
        alert('Private key: '+web3authKey);
        

    }

    
    const sendInitialSolToGachaWallet = async (solAmount, fromWallet, toWallet) => {
         
        const amount = solAmount * LAMPORTS_PER_SOL; // hardcoded to 1 SOL for now

        const solanaWallet = new SolanaWallet(props.provider);

        const pubWal = getCurrentWalletPublicKey();
        const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;
        const TransactionInstruction = SystemProgram.transfer({
        fromPubkey: new PublicKey(fromWallet),
        toPubkey: new PublicKey(toWallet),
        lamports: amount,
        });
        const transaction = new Transaction({
        recentBlockhash: blockhash,
        feePayer: pubWal,
        }).add(TransactionInstruction);
        
        
        const { signature } = await solanaWallet.signAndSendTransaction(transaction);
 
        props.loadSolBalance();

    }

    const sendInitialSolToGachaWalletNew = async (params: any) => {
          
      const userData = getUserSessionData();
      const solAmount = params.initialSol;
      const fromWallet = userData.wallet;
      const toWallet = params.gachaPubWallet;

      const amount = solAmount * LAMPORTS_PER_SOL; // hardcoded to 1 SOL for now

      const solanaWallet = new SolanaWallet(props.provider);

      const pubWal = getCurrentWalletPublicKey();
      const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;
      const TransactionInstruction = SystemProgram.transfer({
      fromPubkey: new PublicKey(fromWallet),
      toPubkey: new PublicKey(toWallet),
      lamports: amount,
      });
      const transaction = new Transaction({
      recentBlockhash: blockhash,
      feePayer: pubWal,
      }).add(TransactionInstruction);
      
      
      const { signature } = await solanaWallet.signAndSendTransaction(transaction);

      props.loadSolBalance();

  }

    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

    const saveDraft = async (event: any) => {
      event.preventDefault(); 
      const userData = getUserSessionData();

      if(userData === null) {
        props.loginWeb3Auth();
      }
      if(getUserSessionData() == null || props.provider == null) {
          props.loginWeb3Auth();
          return;
      }

      handleShowSaving();
      console.log('show saving');
      setTimeout(saveDraftNow, 1500);
    }
    const deleteDraft = async (event: any) => {
      event.preventDefault(); 
      const userData = getUserSessionData();

      if(userData === null) {
        props.loginWeb3Auth();
      }
      if(getUserSessionData() == null || props.provider == null) {
          props.loginWeb3Auth();
          return;
      }

      handleShowDelete();

      
    }
    
    const deleteNow = async() => {
        
      const userData = getUserSessionData();

      const params1 = {
        
          token: userData.token,
          secret: userData.secret,
          
          
          candyMachineId : candyMachineId

      };
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params1)
      };

      const response = await fetch(backend_api_url + 'api/v1/machine/delete', requestOptions);
      const json = await response.json();
      if(json.status == 1) {
         window.location.href = "/";

      }
 

    }
 

    const saveDraftNow = async () => {
      //console.log('saveDraftNow');

      const userData = getUserSessionData();

      //const web3authKey = await getPrivateKeyBase58(props.provider); 

      const params1 = {
          //gachaWallet: gachaWalletPrivateKey,
          //gachaPubWallet: gachaWalletPublicKey,

          //creator : web3authKey, //publisher.toBase58(),
          token: userData.token,
          secret: userData.secret,
          
          name: nameInput.current!.value,
          symbol: symbolInput.current!.value,

          numOfNft: editionInput.current!.value === 'limited' ? numOfOpenNftInput.current!.value : 0,
          numOfNftOpen: editionInput.current!.value === 'open' ? numOfOpenNftInput.current!.value : 0,

          customUserId: getSelectedCreator(),
          machineStatus: statusInput.current ? statusInput.current!.value : '',
          
          numOfImages: allFilesNew.length, 

          edition: editionInput.current!.value,

          tokenAccepted: tokenAcceptedInput.current!.value,
          price: priceInput.current!.value,
          enforceRoyalties: enforceRoyaltiesInput.current!.value,
          royalties: royaltiesInput.current!.value,

          description: descriptionInput.current!.value,
          
          //allFiles: allFilesNew,
          //metaFiles: allFilesNewJson,
          avatar: avatarImage,
          banner: bannerPhoto,
          candyMachineId : candyMachineId,
          isReadOnly : isReadOnly

      };

      const params2 = {
        allFiles: allFilesNew,
        metaFiles: allFilesNewJson,
    };
      await saveDraftGacha(params1,params2, showToastError, handleCloseSaving, hasImageChanges, setHasImageChanges);
 
    }

    const formSubmitHandler = async (event: any) => {
      event.preventDefault();
      props.loginWeb3Auth();

      if(getUserSessionData() == null || props.provider == null) {
          props.loginWeb3Auth();
          return;
      }
 
      if (isWalletReady()) {

        // if(editionInput.current!.value === 'limited') {
        //   if(Number(numOfOpenNftInput.current!.value) !== checkMatchingImages(myMap)) {
        //     showToastError("Limited Edition Error: Collection Size (# of NFTs) must equal Total # of Designs.");
        //     return;
        //   }
        // }
 
        const balance = await loadWalletBalance();
        
        const initialSol = getEstimateSol();
        if( balance < initialSol) {
            setShowBuyCrypto(true);
            showToastError("Needs "+initialSol+" SOLs to be able to create this Toy Machine");
            return;
        } 
        
        if(editionInput.current!.value === 'limited' && Number(numOfOpenNftInput.current!.value) < checkMatchingImages(myMap)) {
          //showToastError("Limited Edition Error: Collection Size (# of NFTs) must equal Total # of Designs.");
          handleShowSubmitConfirm1();
          return;
        } else {
          handleShowSubmitConfirm();
        }
      }else{
          showToastError("Please connect your wallet");
      }
    }

    const getCustomUserWallet = async (userId) => {
      const userData = getUserSessionData();
      if(userData) {
        
        if(userData.wallet) {
          
          const params = {
            token: userData.token,
            secret: userData.secret,
            userId: userId
          }

          const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
          };

          const response = await fetch(backend_api_url + 'api/v1/users/get-pub-wallet', requestOptions);
          const json = await response.json();
          if(json.status == 1) {
            return json.wallet;
          }
        }
      }
      return false;
    }

    const formSubmitHandlerContinue = async () => {

     
        //return;
        handleCloseSubmitConfirm1();
        handleCloseSubmitConfirm();
        
        setShowBuyCrypto(false);

        let errMsg : string;
        
        
        const userData = getUserSessionData();
 
 
        const web3authKey = await getPrivateKeyBase58(props.provider); 

      
		if (isWalletReady()) {
            const balance = await loadWalletBalance();

   

            const gachaWalletSol = editionInput.current!.value === 'open' ? 1 : 0;
             
            const initialSol = getEstimateSol();
            
            //console.log(params1);

            if(balance < initialSol) {
              setShowBuyCrypto(true);
              showToastError("Needs "+initialSol+" SOLs to be able to create this Toy Machine");
              return;
            } 

            const listingAccSeed = Keypair.generate().publicKey.toBase58();
            const listingAcc = getKeypairFromSeed(listingAccSeed);
 

            const privkey = new Uint8Array(listingAcc.secretKey); // content of id.json here
 
            let gachaWalletPrivateKey = base58.encode(privkey);
            let gachaWalletPublicKey = listingAcc.publicKey.toString();
 
            let usdcPrice = priceInput.current!.value;
            let calculatedUsdcPrice = parseFloat( (parseFloat(usdcPrice) / 1.1).toFixed(4));

            let user_wallet = userData.wallet;

            if (getSelectedCreator()) {
              user_wallet = await getCustomUserWallet(getSelectedCreator());
            }

            const params1 = {
                gachaWallet: gachaWalletPrivateKey,
                gachaPubWallet: gachaWalletPublicKey,

                creator : web3authKey, //publisher.toBase58(),
                token: userData.token,
                secret: userData.secret,

                user_wallet : user_wallet,

                logged_in_wallet : userData.wallet,

                customUserId: getSelectedCreator(),
                machineStatus: statusInput.current ? statusInput.current!.value : '',

                name: nameInput.current!.value,
                symbol: symbolInput.current!.value,

                numOfNft: editionInput.current!.value === 'limited' ? numOfOpenNftInput.current!.value : 0,
                numOfNftOpen: editionInput.current!.value === 'open' ? numOfOpenNftInput.current!.value : 0,

                numOfImages: allFilesNew.length, 
    
                edition: editionInput.current!.value,
    
                tokenAccepted: tokenAcceptedInput.current!.value,
                price: usdcPrice, //calculatedUsdcPrice,
                priceOrig: usdcPrice,
                enforceRoyalties: enforceRoyaltiesInput.current!.value,
                royalties: parseFloat(royaltiesInput.current!.value) * 1,
    
                description: descriptionInput.current!.value,
                
                //allFiles: allFilesNew,
                //metaFiles: allFilesNewJson,
                avatar: avatarImage,
                banner: bannerPhoto,
                candyMachineId: candyMachineId,

                initialSol: gachaWalletSol,
                unique_id : Date.now().toString(36)
            }; 

            const params2 = { 
              allFiles: allFilesNew,
              metaFiles: allFilesNewJson, 
          }; 

            setToBeUploaded(allFilesNew.length);
 
 

            //await sendInitialSolToGachaWallet(initialSol, userData.wallet, gachaWalletPublicKey);
          
			try {

                if(!isEdit ) {
                    const balance = await loadWalletBalance();
                    if( minSol > balance) {

                        showToastError("Needs more SOLs to be able to create this posting");
                        return;
                    }
                }
                handleShow();
                setIsCreateSuccessInfo(false);
                //const tag: string[] = selectedTags;

                const tag : string[] = tags.map( tagInfo => tagInfo.text);

                const publisher = await getPublisherNew(props.provider);

                const web3authProvider = await props.web3auth.connect();
 
                const config = getConnectionConfig(); 
               
                const allContents = allFilesNew.map(  file => {
                     return { content: file.file };
                }); 
                 
                if(allContents.length === 0) {
                    handleClose();
                    showToastError("Image is required.");
                    return;
                }
                
                
                const options = { defaultProtocol: 'https', attributes: {
                    rel: 'nofollow'
                  } };
                const newContent = '';//linkifyHtml(convertToHTML(editorState.getCurrentContent()), options);

                if(false && isEdit) {
                
                   
                    
                    props.loadSolBalance();
 

                }else{
                    
                  let symbol = params.symbol;// allFilesNewJson[0].file['symbol'];
                  

                    let hasFile = await hasDesignFile(candyMachineId);


                    if(hasFile) {
                      const candyMachineData = await createGachaCollection(symbol, params1,params2, doZipUpload, handleClose, setUploadedItems, setStepName, setIsCreateSuccess, sendInitialSolToGachaWalletNew);
                    } else {
                      handleClose();
                      showToastError("Please save the collection designs before publishing");
                      return;
                    }

                    //console.log('New Machine');
                    //console.log(candyMachineData);
                    
                    props.loadSolBalance();

                }
                //handleClose();
			} catch (err: any) {
                console.log(err)
				errMsg = (err as Error).message;
                showToastError(errMsg);
                handleClose();
			}
		}else{
            showToastError("Please connect your wallet");

        }
	};  

  const hasDesignFile = async (id) => {
    const userData = getUserSessionData();
    if(userData) {
      
      if(userData.wallet) {
        
        const params = {
          token: userData.token,
          secret: userData.secret,
          id: id
        }
  
        const requestOptions = {
          method: 'POST',
          body: JSON.stringify(params)
        };
  
        const response = await fetch(backend_api_url + 'api/v1/machine/has-design', requestOptions);
        const json = await response.json();
  
        return json.hasDesign == 1 ? true : false;
      }
    }
    return false;
  }
    const getKeypairFromSeed = (seed: string): Keypair => {
        const seedBytes = base58.decode(seed);
        return Keypair.fromSeed(seedBytes);
    };

    const showToastError = (message) => {
      setErrorMessage(message);
      handleShowError();
    } 

  const getCurrentId = () => {
    var tempId = ( Math.floor(Math.random() * 9999999) + '');
    if(id === '') {
      setId( tempId);
    }else{
      tempId = id;
    }
    return tempId;
  }
  const addJsonUpload = (name, id) => {
 
    return; 
    var fieldKey = 'jsonItems' + id;

    var elem = localStorage.getItem(fieldKey);
    var currentJsonFiles = elem !== null ? JSON.parse(elem) : [];

    currentJsonFiles.push(name);

    localStorage.setItem(fieldKey, JSON.stringify(currentJsonFiles));

    // console.log(fieldKey);



  }


  const handleUploadFiles = files => {
    const uploaded = [...uploadedFiles]; 
    const uploaded1 = [...jsonUploaded]; 

    let limitExceeded = false;

    let imagesList = [];
    let jsonList = [];
    for( var i in allFilesNew) {
      imagesList.push(allFilesNew[i]);
    }
    for( var i in allFilesNewJson) {
      jsonList.push(allFilesNewJson[i]);
    }
 
    var id = getCurrentId();

    setIsProcessing(true);

    files.some((file) => {
      //if (uploaded.findIndex((f) => f.name === file.name) === -1) {
      if (allFilesNew.findIndex((f) => f.name === file.name) === -1) {   
          if(file.type === 'application/json') {

              const reader = new FileReader();
              reader.readAsText(file);
              
              addJsonUpload({'name' : file.name, 'type' : "json"}, id);
              uploaded1.push({'name' : file.name, 'type' : "json"});

              reader.onload = (event) => {
                  const imageString = event.target?.result as string;
          
                  setAllFilesNewJson(existing => [...existing, {isFound: false, type: 'json', actualFile: file, name: file.name, file: JSON.parse(imageString)}]);
                  jsonList.push({ actualFile: file, name: file.name, file: JSON.parse(imageString)});

                  setAllFilesMerged(existing => [...existing, {isFound: false, type: 'json', actualFile: file, name: file.name, file: JSON.parse(imageString)}]);
              };

          } 
      }
  })
    
    files.some((file) => {
        //if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        if (allFilesNew.findIndex((f) => f.name === file.name) === -1) {   
            if(file.type !== 'application/json') {
                uploaded.push(file);
                
                addJsonUpload({'name' : file.name, 'type' : "image"}, id);
                uploaded1.push({'name' : file.name, 'type' : "image"});

                const reader = new FileReader();
                reader.readAsDataURL(file);
                
                reader.onload = (event) => {
                    const imageString = event.target?.result as string;
             

                    const getBase64StringFromDataURL =  imageString.replace('data:', '').replace(/^.+,/, '');


                    setAllFilesNew(existing => [...existing, {isFound: false, 
                      type: 'image', 
                      actualFile: file, 
                      name: file.name, 
                      base64: getBase64StringFromDataURL,
                      file: imageString}]);
            
                    imagesList.push({ actualFile: file, name: file.name, file: imageString});

                    setAllFilesMerged(existing => [...existing, {isFound: false, type: 'image', actualFile: file, name: file.name, file: imageString}]);

                };
            } 
        }
    })
    

    setJsonUploaded(uploaded1)
    

    if (!limitExceeded) {
      setUploadedFiles(uploaded)
      // let num = checkMatchingImages(myMap);
      // setNumOfDesigns(num);
      getEstimateSol();
    }
    //setIsProcessing(false);
}

const handleUploadFilesZip = files => {
  const uploaded = [...uploadedFiles]; 
  const uploaded1 = [...jsonUploaded]; 

  let limitExceeded = false;

  let imagesList = [];
  let jsonList = [];
  for( var i in allFilesNew) {
    imagesList.push(allFilesNew[i]);
  }
  for( var i in allFilesNewJson) {
    jsonList.push(allFilesNewJson[i]);
  }

  var id = getCurrentId();

  setIsProcessing(true);

  const filesMerged = [...allFilesMerged]; 

  const filesJson = [...allFilesNewJson]; 
  const filesNew = [...allFilesNew]; 


  files.some((file) => {
    //if (uploaded.findIndex((f) => f.name === file.name) === -1) {
    if (allFilesNew.findIndex((f) => f.name === file.f.name) === -1) {   
        if(file.f.type === 'application/json') {

            addJsonUpload({'name' : file.f.name, 'type' : "json"}, id);

            uploaded1.push({'name' : file.f.name, 'type' : "json"});

            const imageString = file.fromZip.data;
         
            filesJson.push({isFound: false, type: 'json', actualFile: file.f, name: file.f.name, file: JSON.parse(imageString)});

            jsonList.push({ actualFile: file.f, name: file.f.name, file: JSON.parse(imageString)});

            filesMerged.push({isFound: false, type: 'json', actualFile: file.f, name: file.f.name, file: JSON.parse(imageString)});

            myMap.set('json-'+file.f.name.split('.')[0], 1); 

            //console.log(file.f.name);
        } 
    }
})
  
  
  files.some((file) => { 
      if (allFilesNew.findIndex((f) => f.name === file.f.name) === -1) {   
          if(file.f.type !== 'application/json') {
              uploaded.push(file.f);
              
              //addJsonUpload({'name' : file.f.name, 'type' : "image"}, id);

              uploaded1.push({'name' : file.f.name, 'type' : "image"});
            
              const imageString = file.fromZip.data;
           
                  filesNew.push({isFound: false, type: 'image', actualFile: file.f, name: file.f.name, file: imageString});

                  imagesList.push({ actualFile: file.f, name: file.f.name, file: imageString});

                  filesMerged.push({isFound: false, type: 'image', actualFile: file.f, name: file.f.name, file: imageString});
 
                  myMap.set('image-'+file.f.name.split('.')[0], 1);

                 // console.log(file.f.name);
          } 
      }
  })
  
  setAllFilesNewJson(filesJson);
  setAllFilesNew(filesNew);

  let newRecs = [];
 for(var i in filesMerged) {
  if(filesMerged[i].type === 'json') {
    filesMerged[i].isFound = myMap.get('image-'+filesMerged[i].name.split(".")[0]) != null ? true : false;
  } else if(filesMerged[i].type === 'image') {
    filesMerged[i].isFound = myMap.get('json-'+filesMerged[i].name.split(".")[0]) != null ? true : false;
  }
  newRecs.push(filesMerged[i]);
 }
 

  setAllFilesMerged(newRecs);
  setJsonUploaded(uploaded1)
   
  setMyMap(myMap);

  
  console.log('finish reading');
  //console.log(uploaded1);

  if (!limitExceeded) {
    setUploadedFiles(uploaded)
 
    //let num = checkMatchingImages(myMap);

    //console.log('Num of designs ' + num);
    //setNumOfDesigns(num);

    getEstimateSol();
  }
}

const resetNumOfDesigns = () => {
  
  let num = checkMatchingImages(myMap);

  //console.log('resetNumOfDesigns Num of designs ' + num);
  setNumOfDesigns(num);

}

const isJSONExisting = (image, map) => {
  const uploaded1 = [...jsonUploaded]; 

  if(map && map.get("json-"+image.name.split('.')[0])) {
    //console.log('Found in map');
    return true;
  } 

  for(var i in uploaded1) {
    if(uploaded1[i].type === 'json' && image && uploaded1[i].name === image.name.split('.')[0] + '.json') {
      return true;
    }
  }
  return false;

  var fieldKey = "jsonItems" + getCurrentId();
  var elem = localStorage.getItem(fieldKey);
  var currentJSONs = elem !== null ? JSON.parse(elem) : [];


  for(var i in currentJSONs) {
    
    if(currentJSONs[i].type === 'json' && image && currentJSONs[i].name === image.name.split('.')[0] + '.json') {
      return true;
    }
  }
  return false;
}

const isImageExisting = (image) => {

  const uploaded1 = [...jsonUploaded]; 

  //console.log(uploaded1);

  for(var i in uploaded1) {
    if(uploaded1[i].type === 'image' && image && uploaded1[i].name.split('.')[0]+'.json' === image.name) {
      //console.log('Found: ' + image.name);

      return true;
    }
  }
  //console.log('Not found: ' + image.name);

  return false;

  var fieldKey = "jsonItems" + getCurrentId();
  var elem = localStorage.getItem(fieldKey);
  var currentJSONs = elem !== null ? JSON.parse(elem) : [];


  for(var i in currentJSONs) {
    
    if(currentJSONs[i].type === 'image' && image && currentJSONs[i].name.split('.')[0]+'.json' === image.name) {
      return true;
    }
  }
  return false;
}




const renderUploadedImage = (image, index) => {
   
  if(!isJSONExisting(image, myMap)) {
    return <div className="items"
    key={generateRandomKey(index)}  >
    <img 
    onClick={(e) => showNftData(e, image.name)}
    alt={image.name} className="asset-image-new" src={image.file} />

    <br />
      <div className="image-action">
        {!isView && <a href="#" className="image-editable" onClick={(e) => removeAsset(image, e)}>remove</a> }
        {!isView && <Fragment> | <a href="#" className="image-editable" onClick={openImageUploader}>add json</a></Fragment> }
      </div>
    </div>
  }

  return <div className="items"
    key={generateRandomKey(index)} >
 
  <img 
    onClick={(e) => showNftData(e, image.name)}
    alt={image.name} className="asset-image-new" src={image.file} />
     
  <br />
  {!isView && <a href="#" className="remove" onClick={(e) => removeAsset(image, e)}>remove</a> } 
  </div>;
}


const renderUploadedJSONCheck = (file, index) => {
  
  if(file.isFound)
    return false;

  if(!isImageExisting(file)) {
    return true
  }

  return false;
}

const renderUploadedJSON = (file, index) => {
  
  //console.log(file);
  if(file.isFound)
    return null;

  if(!isImageExisting(file)) {
    return <div className="items"
     key={generateRandomKey(index)} >
    <img 
    onClick={(e) => showNftData(e, file.name)}
    alt="" className="asset-image-new empty-image" src="/blank.png" />
    <br />
    <div className="image-action">
      {!isView && <a href="#" className=" image-editable" onClick={(e) => removeAssetJson(file, e)}>remove</a> }
      {!isView && <Fragment> | <a href="#" className=" image-editable" onClick={openImageUploader}>add image</a></Fragment> }
    </div>
  </div>
  }

  return null;
}

const removeAssetJson = (file,e) => {
  e.preventDefault();
  setIsProcessing(false);
  setHasImageChanges(true);
  getEstimateSol();
      let jsonName = file.name.split('.')[0] + '.json';

      setAllFilesNewJson(
        allFilesNewJson.filter(a =>
          a.name !== jsonName
        )
      );

      removeInLocalStorage(jsonName, 'json');
      
      setAllFilesMerged(
        allFilesMerged.filter(a =>
          a.type === 'image' || a.name !== jsonName
        )
      );

}

const removeInLocalStorage = (filename, type) => {

  
  const uploaded1 = [...jsonUploaded]; 

  let newUploaded = [];

  for(var i in uploaded1) {
    if(type === 'json') {
      //remove json only
      if(uploaded1[i].type === 'json' && uploaded1[i].name === filename) {
        ;
      }else{
        newUploaded.push(uploaded1[i]);
      }
    }else if(type === 'image'){
      //remove both

      if(uploaded1[i].type === 'json' && uploaded1[i].name === filename.split('.')[0] + '.json') {
        ;
      }else if(uploaded1[i].type === 'image' && uploaded1[i].name === filename) {
        ;
      }else{
        newUploaded.push(uploaded1[i]);
      }

    }
  }

  setJsonUploaded(newUploaded);

  return true;

  var fieldKey = "jsonItems" + getCurrentId();
  var elem = localStorage.getItem(fieldKey);
  var currentJSONs = elem !== null ? JSON.parse(elem) : [];

  var newData = [];

  for(var i in currentJSONs) {
    
    if(type === 'json') {
      //remove json only
      if(currentJSONs[i].type === 'json' && currentJSONs[i].name === filename) {
        ;
      }else{
        newData.push(currentJSONs[i]);
      }
    }else if(type === 'image'){
      //remove both

      if(currentJSONs[i].type === 'json' && currentJSONs[i].name === filename.split('.')[0] + '.json') {
        ;
      }else if(currentJSONs[i].type === 'image' && currentJSONs[i].name === filename) {
        ;
      }else{
        newData.push(currentJSONs[i]);
      }

    }
    
  }

  localStorage.setItem(fieldKey, JSON.stringify(newData));
}


const removeAsset = (file,e ) => {
  e.preventDefault();
  setIsProcessing(false);
  getEstimateSol();

  setHasImageChanges(true);
      let jsonName = file.name.split('.')[0] + '.json';

      setAllFilesNewJson(
        allFilesNewJson.filter(a =>
          a.name !== jsonName
        )
      );

      setAllFilesNew(
        allFilesNew.filter(a =>
          a.name !== file.name
        )
      );


      setAllFilesMerged(
        allFilesMerged.filter(a =>
          (a.type === 'image' && a.name !== file.name) || (a.type === 'json' && a.name !== jsonName)
        )
      );


      setUploadedFiles(allFilesNew);
      getEstimateSol();
      

      removeInLocalStorage(file.name, 'image');
      
}
const generateRandomKey =(index) => {
    return new Date().getTime() + index;
  }

const handleFileEvent =  (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    if(chosenFiles.length / 2 > 20) {
      showToastError("Only 20 images can be added to a Collection at this time.");
    }else{
      setIsProcessing(true);
      setHasImageChanges(true);
      handleUploadFiles(chosenFiles);
      //setIsProcessing(false);
    }

}


const handleFileEventZip =  async (e) => {
  const chosenFiles = Array.prototype.slice.call(e.target.files)
  //handleUploadFiles(chosenFiles);
  
  const f = chosenFiles[0];
  setIsProcessing(true);
  setHasImageChanges(true);
  await loadZipFileProcessing(f);
  //setIsProcessing(false);

  
}

const clearAllDesigns = () => {
  

  handleShowClear();

}

const clearAllImagesNow =() => {
  setAllFilesNewJson([]);

  setAllFilesNew([]);

  setAllFilesMerged([]);

  setJsonUploaded([]);

  setUploadedFiles([]);
  getEstimateSol();
  
  setNumOfDesigns(0);
 
  handleCloseClear();
  setIsProcessing(false);
  getEstimateSol();
  setHasImageChanges(true);
}

const openImageUploaderZip = () => {
  if(!isView)
    hiddenFileUploaderInputZip.current.click(); 
}
const openImageUploader = () => {
  if(!isView)
    hiddenFileUploaderInput.current.click();


}
const handleFileClick = () => {
   
    hiddenFileInput.current.click();
    hiddenFileInput.current.value = "";
   
}
const handleChange = (event: any) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
        const imageString = event.target?.result as string;
         setAvatarPreview(imageString);
         handleShowPreviewAvatar();

    };
}; 

const handleFileBannerClick = () => {
  // if(!isView)
    hiddenFileBannerInput.current.click();
 
    hiddenFileBannerInput.current.value = "";
}
const handleBannerChange = (event: any) => {
  const file = event.target.files[0];

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (event) => {
      const imageString = event.target?.result as string;
      //  setBannerPhoto(imageString);
      //  setHasBannerPhoto(true);
       setBannerPreview(imageString);
       handleShowPreviewBanner();
  }; 
}; 

const removeBannerPhoto = () => {
   setBannerPhoto('');
  setHasBannerPhoto(false);
}
 
const loadNfts = async (nfts) => {
  const allImages = await Promise.all(
    nfts.map((asset: any) => {
      return { actualFile: null, name: asset.name, file: asset.arweave_url}
      //return asset.arweave_url;
              
              
    })


  );
      
  setAllFilesNew(allImages);

}

const loadDesignFile = (id,designFileUrl) => {
  //console.log(designFileUrl);

  if(designFileUrl !== '') {
    setIsProcessing(true);
    let fullUrl = backend_api_url + 'api/v1/machine/download-design-file?id='+id;

    //loadRemoteZipFile(fullUrl);
    loadRemoteZipFile(backend_api_url + '/' + designFileUrl); 
  }
}

const getZip = async (url) => {
  const { data } = await axios.get(
     url,
     { responseType: "arraybuffer" } 
     );

  return data;
}

const loadRemoteZipFile = async (zipUrl) => {

  try {
    // const response = await fetch(zipUrl);
    // if (!response.ok) {
    //   throw new Error('Network response was not ok');
    // }

    console.log(zipUrl);

    const response = await fetch(zipUrl+"?d="+new Date());

    console.log('parsing');

    const arrayBuffer = await response.arrayBuffer();

    setHasImageChanges(false);

    // Load the ZIP file's contents
    await loadZipFileProcessing(arrayBuffer);
    // this.setState({ images });
  } catch (error) {
    console.error('Error loading and processing ZIP file:', error);
  }
};

const loadZipFileProcessing = async(file) => {
  const zip = new JSZip();

  try {
    

    console.log("Reading...");

    await zip.loadAsync(file);

    console.log("Reading Done...");

    const imagePromises = [];
    const jsonPromises = [];
    const imageFiles = [];
    // Iterate through the files in the ZIP and extract image data
    zip.forEach((relativePath, file) => {
      //console.log(file);
      if(file.name.indexOf('.json') > 0) {
        jsonPromises.push(
          file.async('string').then((data) => ({
            name: relativePath,
            data: (data), // Adjust the MIME type as needed
          }))
        );
      }else{
        var extension = file.name.split(".")[1];
        imagePromises.push(
          file.async('base64').then((base64) => ({
            name: relativePath,
            data: `data:image/`+extension+`;base64,${base64}`, // Adjust the MIME type as needed
          }))
          
        );
      }

      //console.log(file.name);
    });

    // console.log('parse all done');

    //console.log(imagePromises);

    //handleUploadFiles(zip);

    const images = await Promise.all(imagePromises);
    const jsons = await Promise.all(jsonPromises);
    // console.log(images);
    // console.log(jsons);
    
    const allDownloadedFiles = [];

    for(var i in jsons) {
      allDownloadedFiles.push({ 'f': new File([jsons[i].data], jsons[i].name, {type: "application/json"}), 'fromZip' : jsons[i] });
    }

    for(var i in images) {
      allDownloadedFiles.push({'f': base64ToFile(images[i].data, images[i].name), 'fromZip' : images[i] });
      //allDownloadedFiles.push(zipFileToFile(images[i], images[i].name));

    }
  
     

    //console.log(allDownloadedFiles);
//console.log('finish upload processing');
handleUploadFilesZip(allDownloadedFiles);

  } catch (error) {
      console.log(error);
  }
}

const refreshStatus =() => {
  if(statusInput.current) {
    statusInput.current.value = statusValue; 
 
  }
}
const loadEditGacha = async (userId, id, candyMachineId, type) => {

  const userData = getUserSessionData();


  const params = {
      token: userData.token,
      secret: userData.secret,
      userId: userId,
      id: id,
      candyMachineId: candyMachineId,
      type: type
  }

  const requestOptions = {
      method: 'POST',
      body: JSON.stringify(params)
  };

  let apiUrl = backend_api_url + 'api/v1/machine/load-machine'
   
  fetch(apiUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
          if(data.status === 1) { 
             if(data.gacha.is_draft === 1 || (type === 'view-settings' || data.gacha.is_draft === 0)) {
              //console.log(data.gacha);
              
              if(type === 'view-settings') {
                setIsView(true);
                loadNfts(data.nfts);
              }
              else if(type === 'edit-settings')
                setIsEdit(true);

              if(statusInput.current) {
                statusInput.current.value = data.gacha.is_available; 
              }
              if(data.gacha.status > 0) {
                setIsReadOnly(true);
              }
              let val = [];
              if (data.gacha.status === 1) {
                setIsCompleted(true);

                val = [];
                val.push(<option value="0"  key={generateRandomKey(1)}>Hidden</option>);
                if(data.gacha.is_available === 1)
                  val.push(<option value="1" selected key={generateRandomKey(2)}>Available</option>);
                else
                  val.push(<option value="1"  key={generateRandomKey(3)}>Available</option>);

                setStatusJsx(val);

              } else {
                val = [];
                val.push(<option value="0"  key={generateRandomKey(4)}>Hidden</option>);
                

                setStatusJsx(val);
              }

               if(data.is_admin === 1) {
                val = [];
                val.push(<option value="0"  key={generateRandomKey(5)}>Hidden</option>);
                if(data.gacha.is_available === 1)
                  val.push(<option value="1" selected key={generateRandomKey(6)}>Available</option>);
                else
                  val.push(<option value="1"  key={generateRandomKey(7)}>Available</option>);

                  //console.log(val);
                  
                setStatusJsx(val);
               }

               if(data.gacha.is_available === 1) {
                setIsAvailable(data.gacha.is_available); 
               } 
              setAvatarImage(data.gacha.avatar);
              setBannerPhoto(data.gacha.banner_photo);
              if(data.gacha.banner_photo) {
                setHasBannerPhoto(true);
              }
              nameInput.current.value = data.gacha.name;
              symbolInput.current.value = data.gacha.symbol;

              editionInput.current.value = data.gacha.edition;
              if(numOfOpenNftInput.current)
                numOfOpenNftInput.current.value = data.gacha.num_of_nft;
              
              if(data.gacha.edition === 'open') {
                if(data.gacha.is_unlimited === 1) {
                  numOfOpenNftInput.current.value = '#';
                }else{
                  numOfOpenNftInput.current.value = data.gacha.num_of_nft;
                }
              }
              tokenAcceptedInput.current.value = data.gacha.token_accepted;
              priceInput.current.value = data.gacha.price;
              enforceRoyaltiesInput.current.value = data.gacha.enforce_royalties;
              if(royaltiesInput.current)
                royaltiesInput.current.value = data.gacha.royalties;
              descriptionInput.current.value = data.gacha.description;
              

              if(data.creatorData.status === 1) { 
                setIsAdmin(data.creatorData.is_admin === 1 ? true : false);
  
                if(data.creatorData.is_admin !== 1 && data.creatorData.is_admin !== 2 ) { 
                  window.location.href = "/";
  
                }
  
                //setCreators(data.creatorData.creators);
  
                //console.log(data.creatorData.creators);
                setOptions(data.creatorData.creators);

                // let valU = [];
               
                // for(var jj in data.creatorData.creators) {

                //   if(data.gacha.user_id === data.creatorData.creators[jj].id) {
                //     valU.push(<option selected value={data.creatorData.creators[jj].id} key={jj + (new Date()).getTime()}>{data.creatorData.creators[jj].name + ' - (' + data.creatorData.creators[jj].id + ')'}</option>);
                //   } else {
                //     valU.push(<option value={data.creatorData.creators[jj].id} key={jj + (new Date()).getTime()}>{data.creatorData.creators[jj].name + ' - (' + data.creatorData.creators[jj].id + ')'}</option>);
                //   }
                // }
                
                // setCreatorJsx(valU);

                // setCreatorJsx(data.creatorData.creators && data.creatorData.creators.map( (info , index) => {
                //   return info && <option value={info.id} key={index + (new Date()).getTime()}>{info.username + ' - (' + info.id + ')'}</option>;
                // }));
                   
              }
              
              setCurrentUserId(data.gacha.user_id); 
              

              setCandyMachineId(data.gacha.id);

              loadDesignFile(data.gacha.id, data.designFileUrl);

              if(options.length > 0) {
                for(var i in data.creatorData.creators) {
                  if(data.creatorData.creators[i].id === data.gacha.user_id) {
                    setSingleSelections([data.creatorData.creators[i]]);
                  }
                }
              }
             }else{
              //window.location.href = "/";
             }

          }else{
            //window.location.href = "/";
          }
      });

}

const defaultSize = ({ imageSize, visibleArea }) => {      
  var tempHeight = (visibleArea || imageSize).height;
  if(tempHeight > 400) {
    tempHeight = 400;
  }      
  return {                
    width: (visibleArea || imageSize).width,                
    height: tempHeight,            
  };    
}


const viewCollection = () => {
  window.location.href = "/collection/"+gachaAddress+"?d="+Math.floor(Math.random() * 10);
}

const checkMatchingImages = (map) : number => { 

  //console.log("checkMatchingImages");
  let matching = allFilesNew.filter(a =>
    isJSONExisting(a, map)
  )
 

  return matching.length;
}


const fetchUserInfo = async (candyMachineId): Promise<any> => {
        
  const userData = getUserSessionData();

  const params = {
      token: userData.token,
      secret: userData.secret,
      candyMachineId: candyMachineId
  }

  const requestOptions = {
      method: 'POST',
      body: JSON.stringify(params)
  };
  await fetch(backend_api_url + 'api/v1/users/details-with-creators', requestOptions)
      .then(response => response.json())
      .then(data => {
          if(data.status === 1) { 
              setIsAdmin(data.is_admin === 1 ? true : false);

              if(data.is_admin !== 1 && data.is_request_collection !== 2) { 
                window.location.href = "/";

              }

              setCreators(data.creators);
              setOptions(data.creators);
               
                 
          }
      });
}

const donwloadInfo = () => {
  const userData = getUserSessionData();

  const params1 = {
      token: userData.token,
      secret: userData.secret,
      candyMachineId: params.id
  }

  const requestOptions = {
      method: 'POST',
      body: JSON.stringify(params1)
  };
  fetch(backend_api_url + 'api/v1/machine/generate-info', requestOptions)
      .then(response => response.json())
      .then(data => {
          if(data.status === 1) { 
           //   console.log(data.data);

              
            // create file in browser
            const fileName = params.id;
            const blob = new Blob([data.allInfos], { type: "text/plain" });
            const href = URL.createObjectURL(blob);

            // create "a" HTLM element with href to file
            const link = document.createElement("a");
            link.href = href;
            link.download = fileName + ".txt";
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            
          }
      });
}

useEffect(() => {
  const userData1 = getUserSessionData();

  setStatusJsx([<option value="0" key={generateRandomKey(10)}>Hidden</option>]);

  if(userData1) {

    
    

    if(props.type === 'view-settings' || (params.userId && params.id)) {
      if(userData1) {
        //fetchUserInfo(params.id);

        loadEditGacha(params.userId, params.id, params.id, props.type);

      }
    } else {
      fetchUserInfo(0);
    }
  }else{
      window.location.href = "/";
  }



}, []); 
  

useEffect(() => {
   
  if(options.length > 0 && currentUserId > 0) {
    for(var i in options) {
      if(options[i].id === currentUserId) {
        setSingleSelections([options[i]]);
      }
    }
  } 



}, [options, currentUserId]); 
 

const state = {
  disabled: false,
  dropup: false,
  flip: false,
  highlightOnlyResult: true,
  minLength: 0,
  open: undefined,
};


const getSelectedCreator = () => { 
  if(singleSelections.length > 0) {
    return singleSelections[0].id
  }
  return 0;
}

props.setClassInfo("createCollectionPg");


    return (
            <Fragment>
                     
{/* <button onClick={showPrivateKey}>Show Private</button> */}
                     <Form name="frmCollection" id="frmCollection" onSubmit={formSubmitHandler}>
                     <div className="contents">

<h5 className="outer">{isEdit ? "EDIT COLLECTION" : (isView ? "VIEW COLLECTION" :  "CREATE NEW COLLECTION")}</h5>

<div className="rightSideContent aboutCreator createCollection">

  <div className="leftSideDiv">
    {
      avatarImage && avatarImage != '/collection.png' && <img alt="" className="gacha-avatar1 avatar-image" onClick={handleFileClick} src={avatarImage}/>
    }
    {
      avatarImage && avatarImage === '/collection.png' && <img alt="" className="gacha-avatar1 avatar-image" style={{width : "200px", height: "200px"}} onClick={handleFileClick} src=""/>
    }

    { <p style={{"fontSize" : "12px","textAlign" : "center"}} onClick={handleFileClick}>Click to update</p> }
    <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{display: 'none'}}
        />
        
  </div>

 

    <table style={{"border" : 0}} className="createCollectionTable">
      <tbody>
      <tr>
        <td align="right">Collection Name</td>
        <td>
          <input type="text" style={{"width": "45%"}} name="CollectionName"  id="CollectionName" className="textbox" required ref={nameInput} />
          &nbsp;
          {isAdmin && isReadOnly && <a href="#dl" onClick={donwloadInfo}>download</a>}
        </td>	 
      </tr>

      <tr>
        <td align="right">Symbol</td>
        <td>
          <input type="text" className="textbox" required ref={symbolInput} />
        </td>	 
      </tr>

      <tr>
        <td align="right">Edition</td>
        <td>
          <select   name="OpenOrLimited" id="OpenOrLimited" disabled={isReadOnly} ref={editionInput} onChange={changeEdition}>
            <option value="open">Open</option>
            <option value="limited">Limited</option>
          </select>	
        </td>	 
      </tr>


      <tr>
        {/* <td align="right">Open or Limited Edition?</td> */}
        <td align="right">Collection Size (# of NFTs)</td>
        <td>
          <input type="text" name="MaxMint" id="MaxMint" className="textbox" readOnly={isReadOnly} required ref={numOfOpenNftInput} onChange={getEstimateSol}/>
          {/* Open */}
          
        </td>	 
      </tr>
      {
         editionType === 'limited' && false && 
      <tr>
        <td align="right">Max # of NFTs to Mint</td>
        <td>
          <input type="text" name="MaxMint" id="MaxMint" className="textbox" required onChange={getEstimateSol} ref={numOfNftInput}/>
        </td>	 
      </tr>
            
        }
  

      <tr style={{"display": "none"}}>
        <td align="right">Token Accepted</td>
        <td>
          USDC
          <select style={{"display": "none"}} name="TokenAccepted" id="TokenAccepted" ref={tokenAcceptedInput}>
            <option value="USDC">USDC</option>
          </select>	
        </td>	 
      </tr>

      <tr>
        <td align="right">Price per Mint</td>
        <td>
          <input type="text" name="MintPrice" id="MaxMiMintPricent"  className="textbox" required ref={priceInput}/>	
        </td>	 
      </tr>

      <tr>
        <td align="right">Enforce Royalties?</td>
        <td>
          <select name="EnforceRoyalties" id="EnforceRoyalties" disabled={isReadOnly} ref={enforceRoyaltiesInput} onChange={changeEnforceRoyalties}>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>	
        </td>	 
      </tr>

      <tr>
        <td align="right">Royalty (in %)</td>
        <td>
          { enforceRoyalties === 'Yes' ? <input type="text" name="Royalty" readOnly={isReadOnly} id="Royalty" className="textbox" required ref={royaltiesInput}/>	: 'N/A' }
        </td>	 
      </tr>
      {
        isAdmin &&  
        <tr>
          <td  align="right">Creator</td>
          <td> 
              <Typeahead
                {...state}
                disabled={isReadOnly}
                id="basic-typeahead-single"
                labelKey="name"
                onChange={setSingleSelections}
                options={options}
                placeholder="Choose Creator"
                selected={singleSelections}

              /> 
          </td>
        </tr>
      }

      {
        (isAdmin || isSameUser) &&  
        <tr>
          <td  align="right">Status</td>
          <td>
            {
              isAvailable === 1 && <select ref={statusInput} className="is-available" defaultValue="1">
                  <option value="0" >Hidden</option>
                  <option value="1" selected>Available</option>
                </select>
            }
            {
              isAvailable === 0 && <select ref={statusInput} className="not-available" defaultValue={statusValue}>
              {
                statusJsx
              }
            </select>
            }
          </td>
        </tr>
      }
      </tbody>
    </table>
 
 
</div>

<div className="aboutCollection">
  <h6>ABOUT THE COLLECTION</h6>
  <textarea   className="about-collection" id="inputEmail4" placeholder="Description about the collection is mentioned here" required ref={descriptionInput}></textarea>
</div>

<br/>

<div className="uploadPattern uploadDesigns">
  
   { !isReadOnly && <button type="button"  onClick={openImageUploader} className="buttonLink btn-generic">UPLOAD DESIGNS</button> }

   <input className="multi-file-uploader-input" id='fileUpload' ref={hiddenFileUploaderInput}  type='file' multiple onChange={handleFileEvent}/>
                   
   <input className="multi-file-uploader-input" id='fileUploadZip' ref={hiddenFileUploaderInputZip}  type='file'  onChange={handleFileEventZip}/>

   { !isReadOnly && 
   <Fragment>
    <p>note : upload image + json file for meta data</p>
    
    
    <div>
      <a href="#" onClick={openImageUploaderZip}>Bulk Upload</a>&nbsp;-&nbsp;<a href="#" onClick={clearAllDesigns}>Clear Designs</a>
    </div>
   </Fragment>
  }
  { numOfDesigns > 0 && 
    <div> 
      Total # of Designs: {numOfDesigns} 
    </div>
  }

   <div className="uploadPatternDiv">
    
    {
      isProcessing && allFilesMerged.length === 0 && <Fragment><div><b>PROCESSING...</b></div>	</Fragment>
    }
    {
   
 
   allFilesMerged && allFilesMerged.length > 0 && <PaginatedDesigns 
    getEstimateSol={getEstimateSol} 
    isProcessing={isProcessing} 
    renderUploadedImage={renderUploadedImage}
    renderUploadedJSON={renderUploadedJSON}
    resetNumOfDesigns={resetNumOfDesigns}
    items ={allFilesMerged.map( (image, index) => {return image.type === 'image' ? {'data' : image, 'type' : image.type, 'index' : index} : (renderUploadedJSONCheck(image, index) ? {'data' : image, 'type' : image.type, 'index' : index} : null) ; })}
    //items ={allFilesMerged.map( (image, index) => {return image.type === 'image' ? renderUploadedImage(image, index) : renderUploadedJSON(image, index) ; })}
    >

    </PaginatedDesigns>

    }
    
  </div>
</div>

<br/>

<div className="uploadPattern uploadCoverPhoto">
{/* <a onClick={handleFileBannerClick} className="buttonLink ">UPLOAD COVER PHOTO</a> */}

{   <button type="button" onClick={handleFileBannerClick} className="buttonLink btn-generic">UPLOAD COVER PHOTO (1280 x 400)</button> }



<input
        type="file"
        ref={hiddenFileBannerInput}
        onChange={handleBannerChange}
        style={{display: 'none'}}
    />


  <div className="uploadPatternDiv banner_image">
    { 
      bannerPhoto && hasBannerPhoto &&
      <div>
        <a href="#" className="one"><img alt="" src={bannerPhoto}/><br/></a>
        {!isView && <a href="#" className="remove" onClick={removeBannerPhoto}>remove</a> }
      </div> 
    }
  </div>
</div>


<div className="createCollectionSubmit">


  {(!isView || (isCompleted && isAdmin)) && <button type="button" onClick={saveDraft} className="buttonLink btn-custom">SAVE</button>}

  {(!isView || (!isCompleted && isAdmin)) && <a href="#delete" style={{"position" : "relative", "top" : "5px", "left" : "5px"}} onClick={deleteDraft}>DELETE</a>}

</div> 

<div className="createCollectionSubmit">

  {!isView && !isCompleted && isAdmin && <Fragment><button className="btn-custom buttonLink publishButton">PUBLISH NOW</button> <button type="button" className="btn-custom buttonLink publishButton estimate-sol">
    {estimateSol} SOL (est.)
    {/* 0.75 */}
    </button></Fragment>}

</div>  

</div>


</Form>

<Modal className="modal-preview" show={showPreview} onHide={handleClosePreview}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered> 
        <Modal.Header closeButton={true}>
        <Modal.Title>{previewImageName}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div id='NftDetail' style={ {"padding" : "10px", "background" : '#ffffff', "margin" : "0px"} }>
                    {
                      hasJsonData && previewImageUrl && <Fragment>
                    <img alt="" src={previewImageUrl}/>
                    <br/><br/>
                    </Fragment>
                    }
                    {  hasJsonData && previewCollectionName && <Fragment><p>COLLECTION : {previewCollectionName}</p><p>Symbol: {symbolInput.current!.value}</p></Fragment>}

                    { hasJsonData && <Fragment>
                    <p style={{"textAlign": "left"}}>DESCRIPTION : <span className="full-text" dangerouslySetInnerHTML={{ __html: previewImageDesc }} /></p>
                    

                    <p>PROPERTIES<br/></p>
                        <div className="custom-row1">
                            {
                                propertiesJsx ? propertiesJsx : 'N/A'
                            }
                        </div> 
                    <br/>
                    </Fragment>
                    }

                    {
                      showRoyalty && <Fragment>
                        <p style={{"textAlign": "left"}}>Royalty: {royaltiesInput.current!.value}%</p>
                        
                        <p style={{"textAlign": "left"}}> 
                          Royalty share: </p>
                        <p style={{"textAlign": "left"}}>
                          <a rel="noreferrer" href={getExplorerUrl('address')+userWallet+'?cluster='+ cluster} target="_blank">Creator</a> - 90%
                          <br />
                          <a rel="noreferrer" href={'https://solscan.io/address/Ccge7fphYhmpTo87SQ7epZdrN4eSNP9x3JCAqJGWEusK?cluster='+ cluster} target="_blank">Toybot</a> - 10%
                        </p>

                      </Fragment>
                    }

                    {
                      !hasJsonData && <p>No metadata available.</p>
                    }
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>

          <Modal className="modal-processing" show={show} onHide={handleClose} backdrop="static">
            {
              isCreateSuccessInfo && <Modal.Header closeButton={true}>
                      <Modal.Title>{successMessage}</Modal.Title>
                      </Modal.Header>
            }
            <Modal.Body>
              {
                !isCreateSuccessInfo && <Fragment><div>{stepName}</div> <div className="progressBar"><div></div></div> <div>This can take up to 10 minutes (or more). Do not close your browser.</div></Fragment>
              }
              {
                isCreateSuccessInfo && <Fragment><div className="success-create"><button onClick={viewCollection} className="buttonLink btn-generic">View Collection</button></div></Fragment>
              } 

              
 
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

        <Modal className="modal-error" show={showError} onHide={handleCloseError} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
              <Modal.Body>
              <div>
                  {errorMessage}
              </div>
              {
                showBuyCrypto &&   <div style={{"textAlign" : "center", "paddingTop" : "20px"}}>
                  <button className="buttonLink btn-generic" onClick={openOnRamp}>BUY CRYPTO</button>
                </div>
              }
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

        <Modal className="modal-error" show={showSaving} onHide={handleCloseSaving} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
              <Modal.Body>
              <div>
                  Saving Toy Machine...
              </div>
 
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>

        <Modal className="modal-preview" backdrop="static" show={showPreviewAvatar} onHide={handleClosePreviewAvatar}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div>
                    <div>
                        <div style={{"width" : "300px", "margin" : "0 auto"}}>
 
                        {
                            avatarPreview && 
                            <Fragment>
                              <Cropper  src={avatarPreview} 
                              ref={cropperRef} 
                              aspectRatio={{"minimum" : 1, "maximum" : 1}}
                              onChange={onChangeCropper} className={'cropper'}        />
                                    
                              <Navigation className="custom-wrapper__navigation" zoom={absoluteZoom} onZoom={onZoom}/>

                            </Fragment>
                        }
                             
                            
                        </div>
                        
                        
                        <div className="sendNftName" style={{"textAlign" : "center"}}>
                            <button onClick={saveCollectionAvatar} className="buttonLink btn-generic">Save</button>
                        </div>
                    </div>
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>

    <Modal  className="modal-preview"  show={showErrorPurchase} onHide={handleCloseErrorPurchase} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>{mintErrorHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div>
                  {mintError}
              </div>

          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal> 
      
      <Modal  className="modal-error"  show={showSubmitConfirm} onHide={handleCloseSubmitConfirm} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div style={{ "textAlign" : "center"}}>
                Publish this Collection for {estimateSol} SOL?
              </div>

              <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                <button onClick={handleCloseSubmitConfirm} className="buttonLink btn-generic">CANCEL</button>

                <button onClick={formSubmitHandlerContinue} className="buttonLink btn-generic">CONFIRM</button>
              </div>
              
          
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal> 

      <Modal  className="modal-error"  show={showSubmitConfirm1} onHide={handleCloseSubmitConfirm1} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div style={{ "textAlign" : "center"}}>
                Warning: # of Limited NFTs not equal to # of Images
              </div>

              <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                <button onClick={handleCloseSubmitConfirm1} className="buttonLink btn-generic">CANCEL</button>

                <button onClick={handleShowSubmitConfirm} className="buttonLink btn-generic">CONFIRM</button>
              </div>
              
          
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal> 

      <Modal  className="modal-error"  show={showClear} onHide={handleCloseClear} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div style={{ "textAlign" : "center"}}>
                Are you sure you want to clear all images?
              </div>

              <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                <button onClick={handleCloseClear} className="buttonLink btn-generic">CANCEL</button>

                <button onClick={clearAllImagesNow} className="buttonLink btn-generic">CONFIRM</button>
              </div>
              
          
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal> 

    <Modal className="modal-payment" size="sm" show={showStripe} onHide={handleCloseStripe} backdrop="static">
        <Modal.Header closeButton={true}>
        {/* <Modal.Title>Notification</Modal.Title> */}
        </Modal.Header>
            <Modal.Body>
            <div style={{ "textAlign" : "center"}}>
                {
                    secret && <OnRamp secret={secret} showSuccessPayment={showSuccessPayment}></OnRamp>
                }
            </div>

            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
        
    <Modal className="modal-preview" backdrop="static" show={showPreviewBanner} onHide={handleClosePreviewBanner}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div>
                    <div>
                        <div className="crop-banner" style={{"margin" : "0 auto"}}>
 
                        {
                            bannerPreview && 
                            <Fragment>
                              <Cropper  src={bannerPreview} 
                              ref={cropperRefBanner}
                              
                              defaultSize={defaultSize}


                              // minWidth={1280}
                              // minHeight={400} 
                              //imageRestriction="stencil"
                              
                              className={'cropper'}        
                              /> 

                              <Navigation className="custom-wrapper__navigation" zoom={absoluteZoomBanner} onZoom={onZoomBanner}/>

                            </Fragment>

                        }
                             
                            
                        </div>
                        
                        
                        <div className="sendNftName" style={{"textAlign" : "center"}}>
                            <button onClick={saveCollectionBanner} className="buttonLink btn-generic">Save</button>
                        </div>
                    </div>
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>

    <Modal  className="modal-error"  show={showDelete} onHide={handleCloseDelete} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div style={{ "textAlign" : "center"}}>
                Are you sure you want to delete this gacha?
              </div>

              <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                <button onClick={handleCloseDelete} className="buttonLink btn-generic">CANCEL</button>

                <button onClick={deleteNow} className="buttonLink btn-generic">CONFIRM</button>
              </div>
              
          
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal> 

            </Fragment>
        );
}
export default CreateCollection;